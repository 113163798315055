import Main from "../layout/Main";
import {getHomePage} from "@/services";
import {getHomePageMeta} from "@/helper/meta";
import Image from "next/image";
import React, {useEffect, useRef, useState} from "react";
import Rates from "@/components/Rates";
import Poster, {tmdbImageSizeChange} from "@/components/Poster";
import WatchListButtons from "@/components/content/WatchListButtons";
import Link from "next/link";
import {useSelector} from "react-redux";
import Button from "@/components/form/Button";
import {isEmpty} from "lodash";
import {motion, useAnimation} from "framer-motion";
import ListOfTheWeek from "@/components/ListOfTheWeek";
import Head from "next/head";
import GoogleAd from "@/components/GoogleAd";
import ContentListSlider from "@/components/ContentListSlider";
import {useKeenSlider} from "keen-slider/react";

import type {NextPageContext} from "next";
import type {MetaType, StreamListType} from "@/types/dataType";
import type {HomePageResponse} from "@/types/apiType";

const sec = 15;

function Home({meta}: { meta: MetaType }) {
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slides: {
      perView: 1.5,
      spacing: 16,
    },
    breakpoints: {
      "(min-width: 760px)": {
        loop: false,
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
    },
  });

  const currentProgress = useRef(0);
  const [heroSelected, setHeroSelected] = useState<
    "movieOfTheDay" | "seriesOfTheWeek"
  >("movieOfTheDay");
  const controls = useAnimation();
  const streamList: StreamListType[] = useSelector(
    (state: any) => state.generalReducer.streamList
  );
  const genreList: string[] = useSelector(
    (state: any) => state.generalReducer.genreList
  );
  const countries: string[] = useSelector(
    (state: any) => state.generalReducer.countries
  );
  const homePageData: HomePageResponse = useSelector(
    (state: any) => state.generalReducer.homePageData
  );
  const {
    movieOfTheDay,
    seriesOfTheWeek,
    popularTitles,
    listOfWeek,
    imdbTop20,
    imdbTop20Show,
    cinemaCurrent,
    cinemaUpcoming,
  } = homePageData;

  function resetAnimation() {
    currentProgress.current = 0;
    controls.set({width: "0%"});
    controls.start({width: "100%"});
  }

  useEffect(() => {
    if (window.screen.width < 992) return;
    resetAnimation();
  }, [heroSelected]);

  return (
    <Main meta={meta}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height,  initial-scale=1.0, user-scalable=no;user-scalable=0;"
        />
      </Head>
      {renderHero()}
      {renderHeroMobile()}
      <div className="container-fluid container-max-width my-5">
        <h1
          className="hero-selector"
          style={{width: "unset", cursor: "unset"}}
          data-selected={true}
        >
          <span className="text-brand">Film ve Dizi</span> Dünyasına Hoşgeldin!
        </h1>
        <ListOfTheWeek listOfWeek={listOfWeek}/>
        <div className="googlead-home-top">
          <GoogleAd slot={"home1"}/>
        </div>
        <section>
          {streamList.map((i) => {
            const streamPopulars = popularTitles[i.id];
            // @ts-ignore
            if (!streamPopulars?.length) return;
            return (
              <React.Fragment key={i.id}>
                <div
                  className="d-flex align-items-center mb-3 mt-3 mt-lg-4"
                >
                  <Image
                    className='home-stream-image'
                    src={i.imageUrl}
                    alt={i.name}
                    title={i.name}
                    width={45}
                    height={45}
                  />
                  <h2 className="home-section-label ms-2">
                    {i.name} Trendleri
                  </h2>
                </div>
                <ContentListSlider list={streamPopulars}/>
              </React.Fragment>
            );
          })}
          <div className="googlead-home-top">
            <GoogleAd slot={"home2"}/>
          </div>
          {renderCategories()}
          {renderImdb20()}
          {renderImdb20Show()}
          {renderCountries()}
          {cinemaCurrent && cinemaCurrent.length > 0 && renderCinemaCurrent()}
          {cinemaUpcoming && cinemaUpcoming.length > 0 && renderCinemaUpcoming()}
        </section>
      </div>
    </Main>
  );

  function renderHero() {
    const content = homePageData[heroSelected];
    if (!content) return;
    return (
      <div
        className="home-backdrop d-none d-lg-flex"
        key={heroSelected}
        style={
          content?.backdrops?.[0]
            ? {
              backgroundImage: `url(${tmdbImageSizeChange(
                content.backdrops[0],
                "w780"
              )})`,
            }
            : {
              backgroundImage: `url("/images/profile-hero.png")`,
            }
        }
      >
        <section
          key={heroSelected}
          className="container-fluid container-max-width"
          onMouseEnter={() => controls.stop()}
          onMouseLeave={() => {
            controls.set({width: `${currentProgress.current}%`});
            controls.start({
              width: "100%",
              transition: {
                repeat: Infinity,
                repeatType: "loop",
                duration: sec - (sec * currentProgress.current) / 100,
              },
            });
          }}
        >
          <div className="hero-glass-card d-flex">
            <div>
              <p
                className="hero-selector hover-scale--md"
                data-selected={heroSelected === "movieOfTheDay"}
                onClick={() => {
                  resetAnimation();
                  setHeroSelected("movieOfTheDay");
                }}
              >
                Günün Filmi
              </p>
              <div className="hero-selector-divider">
                <motion.div
                  animate={controls}
                  className="selector-divider-full"
                  initial={{width: `${currentProgress.current}%`}}
                  transition={{
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: sec,
                  }}
                  onUpdate={(values: any) => {
                    const num = Number(values.width.split("%")[0]);
                    currentProgress.current = num;
                    if (num > 99.8) {
                      setHeroSelected((state) =>
                        state === "movieOfTheDay"
                          ? "seriesOfTheWeek"
                          : "movieOfTheDay"
                      );
                    }
                  }}
                />
              </div>
              <p
                className="hero-selector hover-scale--md"
                data-selected={heroSelected === "seriesOfTheWeek"}
                onClick={() => {
                  resetAnimation();
                  setHeroSelected("seriesOfTheWeek");
                }}
              >
                Haftanın Dizisi
              </p>
            </div>
            <div className="h-100" style={{minWidth: 300}}>
              <div className="media-picture-wrapper aspect-ratio-10_15">
                <Link href={`/${content.slugUrl}`}>
                  <Poster content={content} priority/>
                </Link>
                <WatchListButtons contentId={content.id} type="icon"/>
              </div>
            </div>
            <div>
              <Link href={`/${content.slugUrl}`} className="hero-glass-title">
                {content.title || content.originalTitle} (
                {content.year || content.yearNumber})
              </Link>
              <p className="hero-glass-description mt-1">
                {content.description}
              </p>
              <p className="hero-categories-title mt-4">Kategori :</p>
              <p className="hero-categories mt-1">
                {content.genres.join(" / ")}
              </p>
              <Rates content={content} className="mt-3"/>
              <Link href={`/${content.slugUrl}`}>
                <Button
                  className="mt-5"
                  size="md"
                  buttonType="primary"
                  style={{width: 214}}
                >
                  Detaylı İncele
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }

  function renderHeroMobile() {
    const contents = [];
    if (movieOfTheDay) contents.push(movieOfTheDay);
    if (seriesOfTheWeek) contents.push(seriesOfTheWeek);

    return (
      <div
        ref={sliderRef}
        className="keen-slider home-slider-body mt-3 ps-3 d-lg-none"
      >
        {contents.map((content, index) => (
          <div className="keen-slider__slide" key={index}>
            <p className="hero-selector" data-selected={true}>
              {index === 0 && "Günün Filmi"}
              {index === 1 && "Haftanın Dizisi"}
            </p>
            <div
              className="hero-glass-card-mobile-body"
              style={
                content?.backdrops?.[0]
                  ? {
                    backgroundImage: `url(${tmdbImageSizeChange(
                      content?.backdrops?.[0],
                      "w185"
                    )})`,
                  }
                  : {
                    backgroundImage: `url("/images/profile-hero.png")`,
                  }
              }
            >
              <div className="hero-glass-card-mobile">
                <div className="media-picture-wrapper aspect-ratio-10_15">
                  <Link href={`/${content.slugUrl}`}>
                    <Poster
                      priority
                      content={content}
                      style={{
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  </Link>
                  <WatchListButtons contentId={content.id} type="icon"/>
                </div>
                <div className="p-3">
                  <p className="hero-glass-title mb-2">
                    {content.title || content.originalTitle} (
                    {content.year || content.yearNumber})
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <Rates content={content} slice={2}/>
                    <Link href={`/${content.slugUrl}`}>
                      <Button buttonType="primary">Detaylı İncele</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  function renderImdb20() {
    return (
      <>
        <div
          className="d-flex align-items-center mb-3 mt-3 mt-lg-4"
        >
          <Image
            className='home-stream-image'
            src={"/images/imdb.svg"}
            alt={"imdb"}
            title={"imdb"}
            width={45}
            height={45}
          />
          <h2 className="home-section-label ms-2">Imdb Film Trendleri</h2>
        </div>
        <ContentListSlider list={imdbTop20}/>
      </>
    );
  }

  function renderImdb20Show() {
    return (
      <>
        <div
          className="d-flex align-items-center mb-3 mt-3 mt-lg-4"
        >
          <Image
            className='home-stream-image'
            src={"/images/imdb.svg"}
            alt={"imdb"}
            title={"imdb"}
            width={45}
            height={45}
          />
          <h2 className="home-section-label ms-2">Imdb Dizi Trendleri</h2>
        </div>
        <ContentListSlider list={imdbTop20Show}/>
      </>
    );
  }

  function renderCinemaCurrent() {
    return (
      <>
        <div
          className="d-flex align-items-center mb-3 mt-3 mt-lg-4"
        >
          <Image
            className='home-stream-image'
            src={"/images/Logo.svg"}
            alt={"imdb"}
            title={"imdb"}
            width={45}
            height={45}
          />
          <h2 className="home-section-label ms-2">Vizyondaki Filmler</h2>
        </div>
        <ContentListSlider list={cinemaCurrent}/>
      </>
    );
  }

  function renderCinemaUpcoming() {
    return (
      <>
        <div
          className="d-flex align-items-center mb-3 mt-3 mt-lg-4"
        >
          <Image
            className='home-stream-image'
            src={"/images/Logo.svg"}
            alt={"imdb"}
            title={"imdb"}
            width={45}
            height={45}
          />
          <h2 className="home-section-label ms-2">Vizyona Girecek Filmler</h2>
        </div>
        <ContentListSlider list={cinemaUpcoming}/>
      </>
    );
  }

  function renderCategories() {
    return (
      <>
        <h2 className="home-section-label mb-2 mt-4 mt-lg-5">
          Kategoriler
        </h2>
        <p className="home-section-description">
          Popüler film ve dizi kategorilerimizi inceleyin!
        </p>
        <div className="home-genres-body">
          {genreList.map((i) => (
            <Link key={i} href={`/icerikler?genres=${i}`}>
              <div className="home-genre">{i}</div>
            </Link>
          ))}
        </div>
      </>
    );
  }

  function renderCountries() {
    return (
      <>
        <h2 className="home-section-label mb-2 mt-4 mt-lg-5">
          Ülkeler
        </h2>
        <p className="home-section-description">
          Popüler film ve dizileri ülkelerine göre filtrele!
        </p>
        <div className="home-genres-body">
          {countries.map((i) => (
            <Link key={i} href={`/icerikler?country=${i}`}>
              <div className="home-genre">{i}</div>
            </Link>
          ))}
        </div>
      </>
    );
  }
}

Home.getInitialProps = async (context: NextPageContext) => {
  // @ts-ignore
  if (isEmpty(context.store.getState().generalReducer.homePageData)) {
    const response = await getHomePage(context);
    if (response.data) {
      // @ts-ignore
      context.store.dispatch({
        type: "SET_HOME_PAGE_DATA",
        payload: response.data,
      });
    }
  }
  return {
    meta: getHomePageMeta(),
  };
};

export default Home;
